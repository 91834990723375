'use client';

import { getTokenPayload } from '@/lib/utils/functions/authentication';
import { usePathname, useRouter } from 'next/navigation';
import { ReactNode, useEffect } from 'react';

export function AuthProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname.startsWith('/auth')) return;

    if (!getTokenPayload()?.userId) {
      if (pathname.startsWith('/app')) {
        return router.push(`/auth`);
      }
      if (pathname.startsWith('/onboarding')) {
        return router.push(`/auth`);
      }
    } else {
      if (pathname.startsWith('/auth') || pathname.startsWith('/code')) {
        router.replace('/');
      }
    }
  }, [pathname, router]);

  return <>{children}</>;
}
